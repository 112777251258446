<template>
    <b-container :class="[isMobile ? 'mt-5 pt-3' : 'mt-3' ]">
        <div v-if="isLoading" class="d-flex flex-column justify-content-center overlay">
            <b-progress variant="success" striped :animated="true" class="w-50 mx-auto">
                <b-progress-bar :value="pdfProgressAmt">
                    <span>Loading - Progress: <strong>{{ pdfProgressAmt }}%</strong></span>
                </b-progress-bar>
            </b-progress>
        </div>
        <h4 class="mt-2">File name: {{titleFileName}}</h4>
        <div class="title-bar mt-3">
            <b-button variant="primary" @click="goBack"><font-awesome-icon icon="fa-solid fa-chevron-left" /> Back</b-button> 
            <b-button-group>
                <b-button variant="secondary"  @click="download" ><font-awesome-icon icon="fa-solid fa-cloud-arrow-down" /> Download</b-button>
                <b-dropdown right>
                    <template #button-content>
                        <font-awesome-icon icon="fa-solid fa-pencil" /> Edit
                    </template>
                    <b-dropdown-item @click="showRenameModal = true"> <font-awesome-icon icon="fa-solid fa-file-signature" /> Re-name</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="showDeleteModal = true"><span class="text-danger"><font-awesome-icon icon="fa-solid fa-trash" /> Delete</span></b-dropdown-item>
                </b-dropdown>
            </b-button-group>
            
        </div>
        <div v-if="extension == '.pdf'" class="mt-4">
            <div>
                <vue-pdf-embed 
                    :source="pdfSource" 
                    @progress="pdfProgress"
                    @rendered="handleDocumentRender" 
                    ref="pdfRef">
                </vue-pdf-embed>
            </div>
        </div>
        
        <div v-else class="mt-4">
            <div class="file-box mt-3">
                <b-img :src="url+'/files/'+contractId+'/'+fileName" fluid class="mt-2 border border-dark"/>
            </div>
        </div>

        <b-modal id="renameModal" v-model="showRenameModal" title="File Rename" @ok.prevent="reNameFile">
            <b-form-group label="File Name" :state="renameState"  invalid-feedback="The 'File Name' can not be blank.">
                <b-form-input v-model="newFileName" placeholder="Enter a new filename" :state="renameState"></b-form-input>
            </b-form-group>
        </b-modal>
        
        <b-modal id="deleteModal" 
            header-bg-variant="danger" 
            header-text-variant="light" 
            okTitle="delete" 
            v-model="showDeleteModal" 
            title="Delete File?" 
        >
            Are you sure you want to delete this file?
            <template #modal-footer>
                <b-button @click="showDeleteModal = false">Cancel</b-button>
                <b-button variant="danger" @click.prevent="deleteFile"><font-awesome-icon icon="fa-solid fa-trash" /> Delete</b-button>
            </template>
        </b-modal>  
    </b-container>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import fileDownload from 'js-file-download'
import { getUnixTime } from 'date-fns'

export default {
    name: 'fileViewer',
    components: {
        VuePdfEmbed
    },
    computed: {
        contractId () {
            return this.$route.params?.contractId ? this.$route.params.contractId : "0"
        },
        jobId () {
            return this.$route.params?.jobId ? this.$route.params.contractId : "0"
        },
        fileName () {
            return this.$route.params.fileName
        },
        isBrochure () {
            return this.$route.params?.brochure ? true : false
        },
        extension () {
            if(this.fileName.substring(this.fileName.length - 4) === 'jpeg'){
                return this.fileName.slice(-5)
            } else {
                return this.fileName.slice(-4)
            }
        },
        isMobile () {
            return this.$state.isMobile
        },
        userInfo () {
          return this.$state.userInfo
        }
    },
    data() {
        return {
            titleFileName: "",
            numPages: undefined,
            pdfSource: "",
            url: this.$baseUrl,
            newFileName: "",
            showRenameModal: false,
            renameState: null,
            showDeleteModal: false,
            isLoading: true,
            pdfProgressAmt: 0,
            pageCount: 1
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
        pdfProgress(data) {
            //console.log(data)
            let fileSize = data.total
            let progress = data.loaded

            let percentComplete = (progress*100)/fileSize
            //console.log(`PercentComplete is: ${percentComplete}`)
            this.pdfProgressAmt = percentComplete
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        async reNameFile() {
            //first check that filename isn't blank
            if(this.newFileName.trim() === ""){
                this.renameState = false
                return
            } else {
                this.renameState = true
            }
            
            this.showRenameModal = false

            let payload = {
                contractId: this.contractId,
                newFileName: `${this.newFileName}${this.extension}`,
                oldFileName: this.fileName
            }
            
            console.table(payload)

            try {
                const res = await this.$axios.put(`/dirlist/rename`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                })
                console.log(res)
                this.titleFileName = res.data.newFileName

                //log it 
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `A File was renamed successfully`,
                    addInfo: `Old Filename: ${this.fileName}, New Filename: ${this.newFileName}${this.extension}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog) 
                //Notify user
                this.$bvToast.toast('👍🏻 File rename was successful.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })

            } catch (err) {
                console.error("Rename ERROR", err)
            }
            
        },
        async deleteFile() {
            let payload = {
                contractId: this.contractId,
                fileName: this.fileName
            }

            try {
                const res = await this.$axios.put(`/dirlist/delete`, payload, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                })
                console.log(res)
                
                //log it 
                const newLog = {
                    logTime: getUnixTime(new Date()),
                    logMsg: `A File was deleted.`,
                    addInfo: `Deleted filename: ${payload.fileName}`,
                    job: this.jobId,
                    logUser: this.userInfo.name
                }

                this.$actions.log(newLog) 

                //Notify user
                this.$root.$bvToast.toast('🚫 File was deleted successfully.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true
                })
                
                //route away from page
                this.$router.go(-1)

            } catch (err) {
                console.error("Rename ERROR", err)
            }

        },
        async download() {
            this.downloaded = true
            let fileData = {
                name: this.fileName,
                contractId: this.contractId,
                type: this.isBrochure ? "brochure" : "file"
            }

            try {
                const res = await this.$axios.put(`/download`, fileData, {
                    headers: { Authorization: `Bearer ${this.$state.token}` },
                    responseType: 'blob'
                })
                console.log(res)
                fileDownload(res.data, fileData.name)

            } catch (err) {
                console.log("Downlaod Error", err)
            }
        },
        
    },
    mounted () {
        
        if(this.extension === ".pdf") {
            if(this.isBrochure) {
                this.pdfSource = `${this.$baseUrl}/brochures/${this.fileName}`
            } else {
                this.pdfSource = `${this.$baseUrl}/files/${this.contractId}/${this.fileName}`
            }
            
            
            /*
            this.src.then(pdf => {
                this.numPages = pdf.numPages
            })
            */
        } else {
            //it's not a pdf...
            this.isLoading = false
        }
        
        this.titleFileName = this.fileName
    }
}
</script>

<style lang="scss" scoped>

.title-bar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
    /* Small screens */
@media all and (max-width: 600px) {
    .title-bar {
        /* On small screens, we are no longer using row direction but column */
        justify-content: space-between;
    }
}

.vue-pdf-embed {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    cursor: pointer;
    z-index: 2;
}
    
</style>